.Shop-right{
    flex:1;
}
.Shop-right>:nth-child(1){
    font-size: 3rem;
  
    color: rgb(66,68,75);
}

.box1-container{

    border: 2px solid black;
    font-size: 30px;
    border: 2px solid;
    margin-left: 30%;
    margin-right: 30%;
    border-radius: 20px;
}
@media (max-width : 768px) {
    .Info{
        margin-top: 0;
        margin-bottom: 30px;
        padding-left: 2rem
    }
    .box1-container{
        margin-left: 10%;
        margin-right: 10%;
        text-align: center;
    }
}
