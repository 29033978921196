.Motor-wrapper{
    width: 100%;
    position: relative;
    padding-bottom: 2rem;
    z-index: 1;
}
.title>:nth-child(1){
    font-size: 3rem;
  
    color: rgb(66,68,75);
}
@media (max-width: 768px) {
    .title{
       
        width: 100%;
       padding-left: 1rem;
       padding-right: 1rem;
        text-align: center;
    }
}