.gal-wrapper{
   width: 100%;
    position: relative;
    padding-bottom: 2rem;
    z-index: 1;
    /* background-color: aqua; */
   
}
.title{
    width: 100%;
}
.gal-container{
    width: 100%;
    display: flex;
   
}
/* right Side         */
.gal-right{
    flex: 1;
    justify-content: space-between;
}
.box-container{
   
    border: 2px solid black;
    font-size: 30px;
    margin-left: 25px;
    margin-right: 25px;
    border-radius: 20px;

}
/*left side*/
.gal-left{
    flex: 1;
    justify-content: space-arround;
    width: 80%;
}
.images-container{
    width: 100%;
}

.image{
    font-size: 60px;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}
.gal-left .box-container{
    width: 80%;
}
@media (max-width : 768px) {
    .gal-container{
        flex-direction: column;
    }
    .box-container{
        text-align: center;
    }
    .gal-left{
        width: 100%;
    }
    
}